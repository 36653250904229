<template>
    <div>
        <PageTitle :title="applyType == '护理咨询' ? '护士列表' : '医生列表'" :backTo="'/depts/' + applyType" fixed smallBackground>
            <List style="margin-top: 15px;">
                <ListItem>
                    <template #top>
                        <p>{{ departmentId != 0 ? departmentName : '所有科室' }}</p>
                    </template>
                </ListItem>
            </List>
        </PageTitle>

        <List>
            <ListItem :to="'/consultOnline/0/' + $route.params.departmentId + '/' + doctor.jobnumber" v-for="doctor in doctors" :key="doctor.jobnumber">
                <template #left>
                    <img :src="doctor.profile_photo || defaultDoctorPicture"/>
                </template>
                <template #right>
                    <p><span>{{ doctor.doctorname }}</span>{{ doctor.area }}<span style="float:right;">¥{{ applyType == '云门诊' ? doctor.registrationFee : doctor.consultingFee }}</span></p>
                    <p>擅长：{{ doctor.doctorskill }}</p>
                </template>
            </ListItem>
        </List>
    </div>
</template>

<script>
    // @ is an alias to /src
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    import { getDoctors } from '@/api/hospital.js';

    export default {
        components: {
            PageTitle,
            List,
            ListItem
        },
        data(){
          return {
              defaultDoctorPicture: require('../assets/default_doctor.png'),
              doctors: [],
              departmentId: 0,
              departmentName: '部门名称',
              applyType: '',
          }
        },
      created() {
        this.applyType = localStorage.getItem('applyType') || '云门诊';
      },
      beforeCreate(){
            getDoctors(this.$route.params.departmentId, localStorage.getItem('applyType') || '云门诊').then(
                (result)=>{
                    this.doctors = result;
                    if(result.length){
                      this.departmentId = this.$route.params.departmentId;
                      this.departmentName = result[0].department;
                    }
                },()=>{});
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .listContainer {
        margin-top: 5px;

        .infoContainer{
            vertical-align: middle;
            padding: 10px;

            p{
                margin: 0;
            }

            .top p {
                text-align: center;
            }

            .left img{
				        border-radius: 3.125rem;
                width: 60px;
            }

            .right{
                p{
                    font-size: 14px;
                    margin-bottom: 5px;

                    span{
                        font-size: 17px;
                        margin-right: 5px;
                    }
                }

                p:last-child{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden; //隐藏超出单元格的部分。
                    color: @gray-6;
                    /*white-space: nowrap; //保证文本内容不会自动换行，如果多余的内容会在水平方向撑破单元格。*/
                    /*text-overflow: ellipsis; //将被隐藏的那部分用省略号代替。*/
                }
            }
        }
    }
</style>
